import { Pipe, PipeTransform } from '@angular/core';
import {PatientDateType} from "../models/enums";

@Pipe({
  name: 'patientDateType'
})
export class PatientDateTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let data = +value;
    if (data === PatientDateType.ApplicationDate) {
      return "Application Date"
    }
    if (data === PatientDateType.DebridementDate) {
      return "Debridement Date"
    }

    return "";  }

}
