import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { StudyCenter } from '../../../models/study-center.modal';
import { StudyCenterEndpoint } from './study-center-endpoint.service';
import {ResponseModel} from "../../../models/response.model";


@Injectable()
export class StudyCenterService {


    constructor(private studyCenterEndpoint: StudyCenterEndpoint) {

    }

  getStudyCenterById(id: number) {
    return this.studyCenterEndpoint.getStudyCenterByIdEndpoint(id).map((response) => <ResponseModel>response);
  }

  getCurrentStudyCenters() {
    return this.studyCenterEndpoint.getCurrentStudyCentersEndpoint().map((response) => <ResponseModel>response);
  }
  getStudyCenters(page: number,search = "",orderBy: string = "", direction: string = "") {
        return this.studyCenterEndpoint.getStudyCentersEndpoint(page,search,orderBy,direction).map((response) => <ResponseModel>response);
    }

    createStudyCenter(studyCenter: StudyCenter) {
        return this.studyCenterEndpoint.
            getCreateStudyCenterEndpoint(studyCenter).map((response: Response) => <any>response);

    }


    updateStudyCenter(studyCenter: StudyCenter) {
        return this.studyCenterEndpoint.
            getUpdateStudyCenterEndpoint(studyCenter).map((response: Response) => <any>response);
    }


    deleteStudyCenter(studyCenterId: Number) {
        return this.studyCenterEndpoint.
            getDeleteStudyCenterEndpoint(studyCenterId).map((response: Response) => <any>response);
    }

}
