export class StudyCenterUsers {

 
  get status(): boolean {
    return this._status;
  }

  set status(value: boolean) {
    this._status = value;
  }

  private _id: string;
  private _firstName: string;
  private _lastName: string;
  private _email: string;
  private _phoneNumber: string;
  private _role: string;
  private _userName: string;
  private _status: boolean = true;
  private _countryCode: string;
  
  private _studyCenterId : string;
  public get studyCenterId() : string {
    return this._studyCenterId;
  }
  public set studyCenterId(v : string) {
    this._studyCenterId = v;
  }
  
  get countryCode(): string {
       return this._countryCode;
      }
    
      set countryCode(value: string) {
        this._countryCode = value;
      }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get firstName(): string {
    return this._firstName;
  }

  set firstName(value: string) {
    this._firstName = value;
  }

  get lastName(): string {
    return this._lastName;
  }

  set lastName(value: string) {
    this._lastName = value;
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  get phoneNumber(): string {
    return this._phoneNumber;
  }

  set phoneNumber(value: string) {
    this._phoneNumber = value;
  }

  get role(): string {
    return this._role;
  }

  set role(value: string) {
    this._role = value;
  }

  get userName(): string {
    return this._userName;
  }

  set userName(value: string) {
    this._userName = value;
  }

  get fullName() : string{
    return this.firstName + ' ' + this.firstName;
  }
  public toString = () : string => {

    return this._firstName + ' ' + this._lastName;
  }
}
