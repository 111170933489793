import { Injectable } from '@angular/core';
import {RoleEndpoint} from "./role-endpoint";
import {Observable} from "rxjs/Observable";
import {ResponseModel} from "../../../models/response.model";

@Injectable()
export class RoleService {

  constructor(private roleEndPoint: RoleEndpoint) { }

  getUserRoles() : Observable<ResponseModel> {
    return this.roleEndPoint.getUserRolesEndpoint().map((response) => <ResponseModel>response);
  }
}
