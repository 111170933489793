import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'operationType'
})
export class OperationTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value === 'I') {
      return "Insert"
    }
    if (value === 'D') {
      return "Delete"
    }
    if (value === 'M') {
      return "Modified"
    }


    return "";  }

}
