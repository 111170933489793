import { Injectable} from '@angular/core';
import { EndpointFactory } from '../../../services/endpoint-factory.service';
import { Observable } from 'rxjs/Observable';
import {HttpParams} from "@angular/common/http";
import {StudyCenterUsers} from "../../../models/study-center-users.modal";


@Injectable()
export class StudyCenterUsersEndpoint extends EndpointFactory {

    private readonly _studyCenterUsersUrl: string = "/api/studyCenterUser";
  private readonly _studyCenterUserCreateUrl: string = "/api/studyCenterUser";
  private readonly _studyCenterUserAssignUrl: string = "/api/studyCenterUser/assign";
  private readonly _studyCenterUserDeAssignUrl: string = "/api/studyCenterUser/deAssign";
    private readonly _studyCenterUserUpdateUrl: string = "/api/studyCenterUser";
    private readonly _studyCenterUserDeleteUrl: string = "/api/studyCenterUser";
    private readonly _studyCenterUserActivationUrl: string = "/api/studyCenterUser/SendActivationEmail";

    get studyCenterUsersUrl() { return this.configurations.baseUrl + this._studyCenterUsersUrl; }
  get studyCenterUserCreateUrl() { return this.configurations.baseUrl + this._studyCenterUserCreateUrl; }
  get studyCenterUserAssignUrl() { return this.configurations.baseUrl + this._studyCenterUserAssignUrl; }
  get studyCenterUserDeAssignUrl() { return this.configurations.baseUrl + this._studyCenterUserDeAssignUrl; }
    get studyCenterUserUpdateUrl() { return this.configurations.baseUrl + this._studyCenterUserUpdateUrl; }
    get studyCenterUserDeleteUrl() { return this.configurations.baseUrl + this._studyCenterUserDeleteUrl; }
    get activaionEmailUrl() { return this.configurations.baseUrl + this._studyCenterUserActivationUrl; }


  assignProviderToStudyCenterEndpoint(studyCenterId: number,repId: string): Observable<any> {
    let requestBody = {
      studyCenterId: studyCenterId,
      repId: repId,
    };
    let header = this.getRequestHeaders();
    return this.http.post(this.studyCenterUserAssignUrl,requestBody,{ headers: header.headers });
  }
  deAssignProviderToStudyCenterEndpoint(studyCenterId: number,repId: string): Observable<any> {
    let requestBody = {
      studyCenterId: studyCenterId,
      repId: repId,
    };
    let header = this.getRequestHeaders();
    return this.http.post(this.studyCenterUserDeAssignUrl,requestBody,{ headers: header.headers });
  }

  getStudyCenterUsersEndpoint(studyCenterId: string, page: number, search: string, orderBy: string, direction: string): Observable<any> {
        let params = new HttpParams().set('studyCenterId',studyCenterId + '').set('page',page + '').set('search',search + '').set('isDescending',direction  === 'desc' ? 'true' : 'false').set('orderBy',orderBy + '');
        let header = this.getRequestHeaders();
        return this.http.get(this.studyCenterUsersUrl,{params: params, headers: header.headers });
    }

  createStudyCenterUsersEndpoint(user:StudyCenterUsers, id: string): Observable<any> {
    let requestBody = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNumber: user.phoneNumber,
      userName: user.userName,
      role: user.role,
      isEnabled: user.status,
      studyCenterId: id,
      countryCode: user.countryCode

    };
    let header = this.getRequestHeaders();
    return this.http.post(this.studyCenterUserCreateUrl, requestBody, { headers: header.headers,});
  }

    updateStudyCenterUsersEndpoint(user:StudyCenterUsers): Observable<any> {

      let params = new HttpParams().set('userId', user.id + '');
      let requestBody = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        isEnabled: user.status,
        userName: user.userName,
        role: user.role,
        studyCenterId :user.studyCenterId,
        countryCode: user.countryCode
      };
      let header = this.getRequestHeaders();
      return this.http.put(this.studyCenterUserUpdateUrl, requestBody, {params: params, headers: header.headers,});
    }
  deleteStudyCenterUsersEndpoint(user:StudyCenterUsers): Observable<any> {
    let params = new HttpParams().set('userId', user.id + '');
    let header = this.getRequestHeaders();
    return this.http.delete(this.studyCenterUserDeleteUrl, {params: params, headers: header.headers,});
  }
  sendActivationEmailEndpoint(user:StudyCenterUsers): Observable<any> {
    let params = new HttpParams().set('email', user.email + '');
    let requestBody = {
      email: user.email
    };
    let header = this.getRequestHeaders();
    return this.http.post(this.activaionEmailUrl,requestBody, {params: params, headers: header.headers,});
  }
}
