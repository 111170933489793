import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { StudyCenterUsersEndpoint } from './study-center-users-endpoint.service';
import {ResponseModel} from "../../../models/response.model";
import {StudyCenterUsers} from "../../../models/study-center-users.modal";


@Injectable()
export class StudyCenterUsersService {


  constructor(private studyCenterUsersEndpoint: StudyCenterUsersEndpoint) {

  }

  getStudyCenterUsers(studyCenterId: string, page: number, search: string,orderBy: string = "", direction: string = "") {
    return this.studyCenterUsersEndpoint.getStudyCenterUsersEndpoint(studyCenterId, page,search,orderBy,direction).map((response) => <ResponseModel>response);
  }

  updateStudyCenterUsers(user: StudyCenterUsers) {
    return this.studyCenterUsersEndpoint.updateStudyCenterUsersEndpoint(user).map((response) => <ResponseModel>response);
  }

  deleteStudyCenterUsers(user: StudyCenterUsers) {
    return this.studyCenterUsersEndpoint.deleteStudyCenterUsersEndpoint(user).map((response) => <ResponseModel>response);
  }
  sendActivationEmail(user: StudyCenterUsers) {
    return this.studyCenterUsersEndpoint.sendActivationEmailEndpoint(user).map((response) => <ResponseModel>response);
  }

  createStudyCenterUsers(user: StudyCenterUsers, id: string) {
    return this.studyCenterUsersEndpoint.createStudyCenterUsersEndpoint(user,id).map((response) => <ResponseModel>response);
  }

  assignProviderToStudyCenter(id: number, repId: string) {
    return this.studyCenterUsersEndpoint.assignProviderToStudyCenterEndpoint(id,repId).map((response) => <ResponseModel>response);
  }
  deAssignProviderToStudyCenter(id: number, repId: string) {
    return this.studyCenterUsersEndpoint.deAssignProviderToStudyCenterEndpoint(id,repId).map((response) => <ResponseModel>response);
  }

}

