import {EndpointFactory} from "../../../services/endpoint-factory.service";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs/Observable";

@Injectable()
export class RoleEndpoint extends EndpointFactory {
  private readonly _userRolesUrl: string = "/api/role";

  get userRoleUrl() { return this.configurations.baseUrl + this._userRolesUrl; }

  getUserRolesEndpoint(): Observable<any> {
    let header = this.getRequestHeaders();
    return this.http.get(this.userRoleUrl,{ headers: header.headers });
  }
}
