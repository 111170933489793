import { NbMenuService } from '@nebular/theme';
import { Component } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-not-found',
  styleUrls: ['./not-found.component.scss'],
  templateUrl: './not-found.component.html',
})
export class NotFoundComponent {

  constructor(private authService: AuthService,
    private router: Router) {
  }
  
    goToHome() {
      if (this.authService.isLoggedIn) {
        this.router.navigate(["/"]);
      } else {
        this.router.navigate(["/login"]);
      }

    }

}
