import { Injectable } from '@angular/core';
import {EndpointFactory} from "../../../services/endpoint-factory.service";
import {Observable} from "rxjs/Observable";
import {Questionnaire} from "../../../models/questionnaire";
import {HttpParams} from "@angular/common/http";
@Injectable({
  providedIn: 'root'
})
export class QuestionnaireEndPointService extends EndpointFactory{

  private readonly _questionnaireUrl: string = "/api/questionnaire";
  private readonly _copyQuestionnaireUrl: string = "/api/questionnaire/copy";

  private readonly _questionnaireByStudyCenterUrl: string = "/api/questionnaire/studyCenter";
  private readonly _assignQuestionnaire: string = "/api/questionnaire/assign";
  private readonly _deAssignQuestionnaire: string = "/api/questionnaire/deAssign";
  get questionnairesUrl() { return this.configurations.baseUrl + this._questionnaireUrl; }
  get copyQuestionnaireUrl() { return this.configurations.baseUrl + this._copyQuestionnaireUrl; }
  get questionnairesByStudyCenterUrl() { return this.configurations.baseUrl + this._questionnaireByStudyCenterUrl; }
  get questionnairesAssignUrl() { return this.configurations.baseUrl + this._assignQuestionnaire; }
  get questionnairesDeAssignUrl() { return this.configurations.baseUrl + this._deAssignQuestionnaire; }

  getQuestionnairesEndpoint(page = 1, search = "",orderBy = "", direction = ""): Observable<any> {
    let params = new HttpParams().set('search',search + '').set('page',page + '').set('isDescending',direction  === 'desc' ? 'true' : 'false').set('orderBy',orderBy + '');
    return this.http.get(this.questionnairesUrl, { headers: this.getRequestHeaders().headers, params: params });
  }

  getCreateQuestionnaireEndpoint(questionnaire: Questionnaire): Observable<any> {

    let requestBody = {
      title: questionnaire.title,
      status: questionnaire.status
    };
    return this.http.post(this.questionnairesUrl, requestBody, this.getRequestHeaders());
  }

  getCopyQuestionnaireEndpoint(questionnaire: Questionnaire): Observable<any> {

    let requestBody = {
      questionnaireId:questionnaire.questionaireID,
      title: questionnaire.title
    };
    return this.http.post(this.copyQuestionnaireUrl, requestBody, this.getRequestHeaders());
  }

  


  getDeleteQuestionnaireEndpoint(questionnaire: Questionnaire): Observable<any> {

    let params = new HttpParams().set('id',questionnaire.questionaireID + '');
    return this.http.delete(this.questionnairesUrl, { headers: this.getRequestHeaders().headers, params: params });
  }
  getUpdateQuestionnaireEndpoint(questionnaire: Questionnaire): Observable<any> {

    let requestBody = {
      status: questionnaire.status,
      title: questionnaire.title
    };
    let params = new HttpParams().set('id',questionnaire.questionaireID + '');
    return this.http.put(this.questionnairesUrl, requestBody,{ headers: this.getRequestHeaders().headers, params: params });
  }


  getQuestionnairesByStudyCenterEndpoint(studyCenterId: string, page: number, search: string) {
    let params = new HttpParams().set('id',studyCenterId + '').set('search',search + '').set('page',page + '');
    return this.http.get(this.questionnairesByStudyCenterUrl, { headers: this.getRequestHeaders().headers, params: params });
  }

  assignQuestionnaireToStudyCenterEndpoint(id: number, questionnaireId: number) {
    let requestBody = {
      studyCenterId: id,
      questionnaireId: questionnaireId,
    };
    let header = this.getRequestHeaders();
    return this.http.post(this.questionnairesAssignUrl,requestBody,{ headers: header.headers });
  }

  deAssignQuestionnaireToStudyCenterEndpoint(studyCenterId: number, questionnaireId: number) {
    let requestBody = {
      studyCenterId: studyCenterId,
      questionnaireId: questionnaireId,
    };
    let header = this.getRequestHeaders();
    return this.http.post(this.questionnairesDeAssignUrl,requestBody,{ headers: header.headers });
  }
}
