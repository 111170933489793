import { NgModule } from '@angular/core';
import {QuestionTypePipe} from "./question-type.pipe";
import { PatientDateTypePipe } from './patient-date-type.pipe';
import { OperationTypePipe } from './operation-type.pipe';

@NgModule({
  imports: [
  ],
  declarations: [QuestionTypePipe, PatientDateTypePipe, OperationTypePipe],
  exports:[QuestionTypePipe,PatientDateTypePipe, OperationTypePipe]
})
export class PipeModule { }
