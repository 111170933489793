import { Injectable} from '@angular/core';
import { EndpointFactory } from '../../../services/endpoint-factory.service';
import { Observable } from 'rxjs/Observable';
import { StudyCenter } from '../../../models/study-center.modal';
import {HttpParams} from "@angular/common/http";


@Injectable()
export class StudyCenterEndpoint extends EndpointFactory {

  private readonly _studyCentersUrl: string = "/api/studyCenter";
  private readonly _currentStudyCentersUrl: string = "/api/studyCenter/current";
    private readonly _studyCenterCreateUrl: string = "/api/studyCenter";
    private readonly _studyCenterUpdateUrl: string = "/api/studyCenter";
    private readonly _studyCenterDeleteUrl: string = "/api/studyCenter";

  get studyCentersUrl() { return this.configurations.baseUrl + this._studyCentersUrl; }
  get currentStudyCentersUrl() { return this.configurations.baseUrl + this._currentStudyCentersUrl; }
    get studyCenterCreateUrl() { return this.configurations.baseUrl + this._studyCenterCreateUrl; }
    get studyCenterUpdateUrl() { return this.configurations.baseUrl + this._studyCenterUpdateUrl; }
    get studyCenterDeleteUrl() { return this.configurations.baseUrl + this._studyCenterDeleteUrl; }



  getStudyCenterByIdEndpoint(id: number): Observable<any> {
    return this.http.get(this.studyCentersUrl + '/' + id, { headers: this.getRequestHeaders().headers });
  }
  getCurrentStudyCentersEndpoint(): Observable<any> {
    return this.http.get(this.currentStudyCentersUrl, {headers: this.getRequestHeaders().headers });
  }
    getStudyCentersEndpoint(page: number,search: string,orderBy: string, direction: string): Observable<any> {
      let params = new HttpParams().set('page',page + '').set('search',search + '').set('isDescending',direction  === 'desc' ? 'true' : 'false').set('orderBy',orderBy + '');
      return this.http.get(this.studyCentersUrl, {params: params, headers: this.getRequestHeaders().headers });
    }

    getCreateStudyCenterEndpoint(studyCenter: StudyCenter): Observable<Response> {

      let requestBody = {
        city: studyCenter.city,
        country: studyCenter.country,
        address: studyCenter.address,
        hospitalName: studyCenter.hospitalName,
        state: studyCenter.state,
        status: studyCenter.status,
        zipCode: studyCenter.zipCode,
      };
        return this.http.post(this.studyCenterCreateUrl,requestBody , this.getRequestHeaders())
            .map((response: Response) => {
                return response;
            });
    }


    getUpdateStudyCenterEndpoint(studyCenter: StudyCenter): Observable<Response> {

      let requestBody = {
        city: studyCenter.city,
        country: studyCenter.country,
        address: studyCenter.address,
        hospitalName: studyCenter.hospitalName,
        state: studyCenter.state,
        status: studyCenter.status,
        zipCode: studyCenter.zipCode,
        studyCenterId: studyCenter.studyCenterId
      };
        return this.http.put(this.studyCenterUpdateUrl, requestBody, this.getRequestHeaders())
            .map((response: Response) => {
                return response;
            });
    }



    getDeleteStudyCenterEndpoint(studyCenterId: Number): Observable<Response> {

      let params = new HttpParams().set('studyCenterId',studyCenterId + ''); //.set('search',search + '').set('isDescending',direction  === 'desc' ? 'true' : 'false').set('orderBy',orderBy + '');
      return this.http.delete(this.studyCenterDeleteUrl, { headers: this.getRequestHeaders().headers, params: params })
            .map((response: Response) => {
                return response;
            });
    }
}
