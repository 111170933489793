import { Pipe, PipeTransform } from '@angular/core';
import {QuestionType} from "../models/enums";

@Pipe({
  name: 'questionType'
})
export class QuestionTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {

    let data = +value;
    if (data === QuestionType.DefinitiveClosure) {
      return "Definitive Closure"
    }
    if (data === QuestionType.Followup) {
      return "Followup"
    }
    if (data === QuestionType.DressingChange) {
      return "Dressing Change"
    }
    if (data === QuestionType.InitialIntervention) {
      return "Initial Intervention"
    }
    if (data === QuestionType.ReApplication) {
      return "Reapplication"
    }
    return "";
  }

}
