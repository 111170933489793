import { Injectable } from '@angular/core';
import {QuestionnaireEndPointService} from "./questionnaire-end-point.service";
import {ResponseModel} from "../../../models/response.model";
import {Questionnaire} from "../../../models/questionnaire";

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService {

  constructor(private endPoint: QuestionnaireEndPointService) {

  }


  getQuestionnaires(page = 1, search = "",orderBy = "", direction = "") {
    return this.endPoint.getQuestionnairesEndpoint(page,search,orderBy,direction).map((response) => <ResponseModel>response);
  }
  createQuestionnaire(questionnaire: Questionnaire) {
    return this.endPoint.getCreateQuestionnaireEndpoint(questionnaire).map((response) => <ResponseModel>response);
  }

  copyQuestionnaire(questionnaire: Questionnaire) {
    return this.endPoint.getCopyQuestionnaireEndpoint(questionnaire).map((response) => <ResponseModel>response);
  }

  updateQuestionnaire(questionnaire: Questionnaire) {
    return this.endPoint.getUpdateQuestionnaireEndpoint(questionnaire).map((response) => <ResponseModel>response);
  }

  deleteQuestionnaire(questionnaire: Questionnaire) {
    return this.endPoint.getDeleteQuestionnaireEndpoint(questionnaire).map((response) => <ResponseModel>response);
  }

  getQuestionnairesByStudyCenter(studyCenterId: string, page: number, search: string) {
    return this.endPoint.getQuestionnairesByStudyCenterEndpoint(studyCenterId,page,search).map((response) => <ResponseModel>response);
  }

  assignQuestionnaireToStudyCenter(id: number, questionnaireId: number) {
    return this.endPoint.assignQuestionnaireToStudyCenterEndpoint(id,questionnaireId).map((response) => <ResponseModel>response);

  }

  deAssignQuestionnaireToStudyCenter(studyCenterId: number, questionnaireId: number) {
    return this.endPoint.deAssignQuestionnaireToStudyCenterEndpoint(studyCenterId,questionnaireId).map((response) => <ResponseModel>response);
  }
}
