export class Comorbidity {
  get comorbidityId(): number {
    return this._comorbidityId;
  }

  set comorbidityId(value: number) {
    this._comorbidityId = value;
  }
  private _comorbidityId: number;
  private _name: string;

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  public toString = () : string => {

    return `(${this.name})`;
  }

  
  private _status : boolean;
  public get status() : boolean {
    return this._status;
  }
  public set status(v : boolean) {
    this._status = v;
  }
  

}
